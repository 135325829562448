import { Options, Vue } from "vue-class-component";

import questionsDe from "!raw-loader!./assets/QuestionsDE.txt";
import questionsEn from "!raw-loader!./assets/QuestionsEn.txt";

@Options({})
export default class App extends Vue {
    t = "";
    current_text = "";
    language = "de";
    options =
        this.language == "de" ? questionsDe.split("\n") : questionsEn.split("\n");
    isprimary = true;


    today = new Date();
    current_month: number = this.today.getMonth();

    get holiday() {
        // Remove when Ukraine wins the war
        return "ua";   
        if ((new Date("04/01/2022") < this.today && this.today < new Date("04/18/2022")) ||
            (new Date("04/01/2023") < this.today && this.today < new Date("04/10/2023")) ||
            (new Date("08/03/2024") < this.today && this.today < new Date("04/01/2024")) ||
            (new Date("04/06/2025") < this.today && this.today < new Date("04/21/2026")) ||
            (new Date("03/23/2026") < this.today && this.today < new Date("04/06/2026"))
        ) { return "easter"; }
        else if (this.today.getMonth() == 9 && this.today.getDate() > 21) { return "halloween" }
        else if (this.today.getMonth() == 11) { return "christmas"; }
        else if (this.today.getMonth() == 8 && this.today.getDate() > 20) { return "oktoberfest"; }
        else { return "no_holiday"; }
    }

    get season() {
        if(this.holiday != "no_holiday"){ return "basic_season"}
        if (this.current_month < 2) { return "winter"; }
        else if (this.current_month < 5) { return "spring"; }
        else if (this.current_month < 8) { return "summer"; }
        else if (this.current_month < 11) { return "autumn"; }
        else { return "winter"; }
        // switch (this.current_month) {
        //   case 0:
        //     return "winter";
        //   case 1:
        //     return "winter";
        //   case 2:
        //     return "spring";
        //   case 3:
        //     return "spring";
        //   case 4:
        //     return "spring";
        //   case 5:
        //     return "summer";
        //   case 6:
        //     return "summer";
        //   case 7:
        //     return "summer";
        //   case 8:
        //     return "autumn";
        //   case 9:
        //     return "autumn";
        //   default:
        //     return "basic_season";
        // }
    }

    button_theme = {
        winter: "far fa-snowflake",
        spring: "fa fa-seedling",
        summer: "fas fa-ice-cream",
        autumn: "fas fa-umbrella",
        christmas: "fas fa-candy-cane",
        easter: "fas fa-egg",
        halloween: "fas fa-broom",
        oktoberfest: "fas fa-beer",
        basic_season: "fas fa-exchange-alt",
        no_holiday: "fas fa-exchange-alt",
        ua: "fas fa-exchange-alt",
    };

    // background_theme = "#20286d"

    get isBasic() {
        return !this.isprimary;
    }

    random_text() {
        this.t = this.current_text;
        while (this.t == this.current_text) {
            this.t = this.options[Math.floor(Math.random() * this.options.length)];
        }
        this.current_text = this.t;
    }

    to_english() {
        if (this.language != "en") {
            this.language = "en";
            this.options = questionsEn.split("\n");
            this.t = this.options[Math.floor(Math.random() * this.options.length)];
        }
    }

    to_german() {
        if (this.language != "de") {
            this.language = "de";
            this.options = questionsDe.split("\n");
            this.t = this.options[Math.floor(Math.random() * this.options.length)];
        }
    }
}